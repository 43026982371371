body {
   background-color: #f4f4f4;
}
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 600px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.leaflet-container {
  width: 100%;
  height: 90vh;
  z-index: 1;
}

.btn-primary-ib {
  --bs-btn-color: #fff;
  --bs-btn-bg: #9B8C64;
  --bs-btn-border-color: #9B8C64;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5a5033;
  --bs-btn-hover-border-color: #5a5033;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5a5033;
  --bs-btn-active-border-color: #5a5033;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #5a5033;
  --bs-btn-disabled-border-color: #0d6efd
}

.btn-primary.custom-btn {
  --bs-btn-color: #fff;
  --bs-btn-bg: #9B8C64;
  --bs-btn-border-color: #9B8C64;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5a5033;
  --bs-btn-hover-border-color: #5a5033;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5a5033;
  --bs-btn-active-border-color: #5a5033;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #5a5033;
  --bs-btn-disabled-border-color: #0d6efd
}
.nav-link {
  color: #A9A7A8 !important;
}
.div{

  padding:3px;

}

.active{
  color:#fcfcfc !important;
  font-size: 18px;
 }

 .logo-ib{
  color:#fcfcfc !important;
  font-family: "Times New Roman";
  font-size: 20px;
 }

 @media screen and (max-width: 480px) {
  .carousel-container {
    width: 42vw;
   }
 }

 .slider-container {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: -60px;
  height: 30vw;
  width:   25vw;
  }
@media screen and (max-width: 480px) {
  .slider-container {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: -40px;
    height: 50vw;
    width:  90vw;
    }
  }

@media screen and (max-width: 480px) {
  .MuiCardActions-root {
    height: 12vw;
  }
}

.img-carousel-container {
  /*width: 600px;*/
  width: 7vw;
}

#map { height: 100%; }

.search_modal {
  opacity: 0;
  transition: opacity 2000ms ease-in-out;
}

.search_modal--after-open{
  opacity: 1;
}

.search_modal--before-close{
  opacity: 0;
}

.button {
  background-color: #A9A7A8;
  border: none;
  color: white;
  padding: 4px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 2px 2px;
  margin-left: 46.5%;
  border-radius: 4px;
  cursor: pointer;

}
.button .icon {
  float: left;
  width: 4px;
  height: 4px;
  margin-left: 10px
}  
.button .span {
  float: center;
  width: 2px;
  height: 2px;
}  